/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import ContextProvider from './Context';
import App from './FeatureApp';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'layer-manager': LayerManagerV27;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'locale-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
      '@audi/audi-ui-react-v2': '^2',
    },
  },
  optionalDependencies: {
    featureServices: {
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'layer-manager': '^2.7.2',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const contentService = featureServices['audi-content-service'];

    const trackingService = featureServices['audi-tracking-service'] as TrackingServiceV2;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    const localeService = featureServices['locale-service'];
    const renderModeService = featureServices['audi-render-mode-service'];
    const footnoteReferenceServiceScopeManager = featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    const layerManager = featureServices['layer-manager'];

    return {
      render: () => {
        return (
          <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
            <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
              <ContextProvider
                featureAppEnv={{ featureAppId, featureAppName }}
                localeService={localeService}
                loggerService={loggerService}
                trackingService={trackingService}
                vueFormatterService={vueFormatterService}
                layerManager={layerManager}
              >
                <UniversalEditorProvider
                  contentService={contentService}
                  renderModeService={renderModeService}
                >
                  <App contentService={contentService} />
                </UniversalEditorProvider>
              </ContextProvider>
            </div>
          </FootnoteContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
